<template>
  <div class="headerbox">
    <div class="headerpage">
      <div class="headermain">
        <div class="logo">
          <img @click="goHome" src="../../assets/image/logo.png" style="cursor: pointer;" alt="">
        </div>
        <div class="header">
          <div class="headertit">
            <template v-for="menu in dataList">
              <template v-if="!menu.children">
                <div class="elmenu" :key="menu.id" @click="goPath(menu.link)">
                  <p v-if="language === 0 && parseInt(menu.zhIsShow) === 1 "  :class="act==menu.link?'actMenu':''">{{menu.zhName}}</p>
                  <p v-if="language === 1 && parseInt(menu.enIsShow) === 1 "  :class="act==menu.link?'actMenu':''">{{menu.enName}}</p>
                </div>
              </template>
              <template v-if="menu.children">
                <el-dropdown :key="menu.id" class="custom-dropdown" placement="bottom-start">
                  <div class="elmenu">
                    <span v-if="language === 0 && parseInt(menu.zhIsShow) === 1" :class="act==menu.link?'actMenu':''">{{menu.zhName}} <i class="el-icon-arrow-down el-icon--right"></i></span>
                    <span v-if="language === 1 && parseInt(menu.enIsShow) === 1" :class="act==menu.link?'actMenu':''">{{menu.enName}} <i class="el-icon-arrow-down el-icon--right"></i></span>
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="subMenu in menu.children" :key="subMenu.id" :index="subMenu.link">
                      <span v-if="language === 0 && parseInt(subMenu.zhIsShow) === 1" @click="goPath(subMenu.link)">{{subMenu.zhName}}</span>
                      <span v-if="language === 1 && parseInt(subMenu.enIsShow) === 1" @click="goPath(subMenu.link)">{{subMenu.enName}}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </template>
          </div>
          <div class="serch">
						<el-input v-model="inputserch" :placeholder="$t('productCenter.headerInputPlaceHolder')"></el-input>
            <div class="icon" @click="changeBuild">
              <i class="el-icon-search"></i>
            </div>
          </div>
          <div class="lang" @click="langSwitch">
            <img v-if="language === 0" src="../../assets/image/english.svg" alt="">
            <img v-if="language === 1" src="../../assets/image/china.svg" alt="">
          </div>
        </div>
      </div>
      <!-- <div class="lang" @click="langSwitch">
        <img v-if="language === 0" src="../../assets/image/english.svg" alt="">
        <img v-if="language === 1" src="../../assets/image/china.svg" alt="">
      </div> -->
    </div>
  </div>
</template>

<script>
import { getMenutree, getProductQuery } from '@/api/home'
export default {
  name: 'NavigationBar',
  data () {
    return {
			inputserch: '',
			
      buildingSiteId: '',
      productNamelist: [],
      casNolist: [],
      itemNolist: [],
      dataList: [],
      act: ''
    }
  },
  // created () {
  //   this.getList()
  // },
  mounted () {
    // this.getList()
  },
  computed: {
    language () {
      return this.$store.state.base.language
    },
    languageVal () {
      return this.$store.state.base.languageVal
    }
  },
  watch: {
    language: {
      immediate: true,
      handler: function (newValue, oldValue) {
        this.getList()
        this.djclang()
      }
    },
    '$route' (val, from) {
      // 拿到目标参数 val.query.id 去请求接口
      const path = val.query.link
      if (path && path.includes('/aboutUs')) {
        const currentpath = '/aboutUs'
        this.act = currentpath
      } else if (path && path.includes('/serviceCenter')) {
        const currentpath = '/serviceCenter'
        this.act = currentpath
      } else if (path && path.includes('/productCenter')) {
        const currentpath = '/productCenter'
        this.act = currentpath
      } else if (path && path.includes('/newsCenter')) {
        const currentpath = '/newsCenter'
        this.act = currentpath
      } else {
        this.act = path
      }
    }
  },
  methods: {
    changeBuild (value) {
      console.log(value)
      this.$router.push({
        path: '/productCenter/list',
        query: {
          link: '/productCenter/list',
          inputserch: this.inputserch
        }
      })
      this.productNamelist = []
      this.casNolist = []
      this.itemNolist = []
      this.buildingSiteId = ''
    },
    serch (text) {
      // console.log(arguments)
      getProductQuery(text).then(res => {
        if (res.data.code === 200) {
          res.data.data.forEach(item => {
            if (item.productName) {
              this.productNamelist.push({ id: item.id, productName: item.productName })
            }
            if (item.casNo) {
              this.casNolist.push({ id: item.id, casNo: item.casNo })
            }
            if (item.itemNo && item.itemNo !== '') {
              this.itemNolist.push({ id: item.id, itemNo: item.itemNo })
            }
          })
        }
      })
    },
    goPath (path) {
      this.$store.commit('base/CHANGE_PATH_STATE', path)
      this.$store.commit('base/CHANGE_PATH_FW', path)
      this.$store.commit('base/CHANGE_PATH_XW', path)
      this.$router.push({
        path: path,
        query: {
          link: path
        }
      })
      if (path.includes('/aboutUs')) {
        const currentpath = '/aboutUs'
        this.act = currentpath
        if (path && document.getElementById(path)) {
          document.getElementById(path).scrollIntoView({ behavior: 'smooth' })
        }
      } else if (path.includes('/serviceCenter')) {
        const currentpath = '/serviceCenter'
        this.act = currentpath
      } else if (path.includes('/productCenter')) {
        const currentpath = '/productCenter'
        this.act = currentpath
      } else if (path.includes('/newsCenter')) {
        const currentpath = '/newsCenter'
        this.act = currentpath
      } else {
        this.act = path
      }
    },
    langSwitch () {
      this.$store.commit('base/CHANGE_NAV_STATE')
    },
    djclang () {
      this.$i18n.locale = this.languageVal
    },
    getList () {
      getMenutree(this.language).then(res => {
        if (res.data.code === 200) {
          this.dataList = res.data.data
					this.$store.commit('base/CHANGE_MENUS', this.dataList)
        } else {
          this.dataList = []
        }
      })
    },
		goHome () {
		  this.$router.push({
		    path: '/'
		  })
		},
  }
}
</script>

<style lang="scss" scoped>
.el-dropdown{
  font-size: 18px;
  font-weight: 400;
  color: #172B4D;
}
.el-popper {
  overflow: auto;
}
.el-dropdown-menu__item {
  &:hover {
    background-color: #0052CC;
    color: #FFFFFF;
  }
	
	&>span {
		display: block;
	}
}
.el-menu{
  border-bottom: 0px;
}
::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title{
  font-size: 16px;
  font-weight: 400;
}
::v-deep .el-menu--horizontal>.el-menu-item{
  font-size: 16px;
  font-weight: 400;
}
.el-menu--horizontal>.el-menu-item.is-active{
  font-weight: 900;
  font-size: 16px;
}
.headerbox{
  width: 100%;
  height: 72px;
  background: #FFFFFF;
  box-shadow: 0 2px 6px rgba(0, 0, 0.5, 0.1);
  .headerpage{
    width: 1400px;
    margin: 0 auto;
    position: relative;
    .lang{
    position: absolute;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
      width: 26px;
      height: 26px;
      img{
        width: 26px;
        height: 26px;
      }
    }
  }
  .headermain{
    font-family: HarmonyOS_Sans_SC_Regular;
    // width: 1100px;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
		
    .logo{
      width: 120px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: start;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .header{
      display: flex;
      align-items: center;
      justify-content: start;
      align-items: center;
      .headertit{
        height: 72px;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 400;
        color: #172B4D;
        cursor: pointer;
        .elmenu{
          margin-right: 30px;
          p,span{
            display: block;
            height: 70px;
            line-height: 72px;
          }
          .subMenu{
            position: absolute;
            bottom: -100px;
            left: center;
            background-color: #FFFFFF;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
        .actMenu{
          // height: 72px;
          font-size: 18px;
          font-weight: 900;
          color: #0052CC;
          border-bottom: 2px solid #0052CC;
        }
      }
      // .lang{
      //   width: 22px;
      //   height: 22px;
      //   img{
      //     width: 22px;
      //     height: 22px;
      //   }
      // }
      .serch{
				width: 200px;
        // margin-left: 20px;
        cursor: pointer;
        position: relative;
        ::v-deep .el-icon-arrow-up:before{
          content: '';
        }
        // ::v-deep .el-select .el-input__inner{
        //   width: 150px;
        // }
        .icon{
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          color: #C0C4CC;
        }
      }
    }
  }
}
</style>
